import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-crop-doctor-info',
  templateUrl: './crop-doctor-info.component.html',
  styleUrls: ['./crop-doctor-info.component.css']
})
export class CropDoctorInfoComponent implements OnInit {

  defaultLanguage = 'en'
  detectedDevice = 'desktop';
  videoSrc = 'https://www.youtube.com/embed/k2ThNCz3mpE?rel=0'
  selectedLanguageISO!: string;

  constructor(private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private activeRoute: ActivatedRoute){}

  ngOnInit(): void {
    this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
      .subscribe(screen => {
        if (screen.matches) {
          this.detectedDevice = 'mobile'
        }
        else {
          this.detectedDevice = 'desktop'
        }
      })
    this.selectedLanguageISO = this.activeRoute.snapshot.paramMap.get('languageISO') || 'en';
    this.translateService.use(this.selectedLanguageISO)
  }
}
