<div class="container" (click)="appDownload()">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap" rel="stylesheet">
    <div class="row">
        <div class="col-img">
            <img src="../../assets/app_download_banner_image.png" alt="">
        </div>
        <div class="col-text">
            <span>{{ 'translateStrings.download_farmrise_mobile_app' | translate }}</span>
        </div>
        <div class="col-btn">
            <button class="btn-download-app">{{ 'translateStrings.download_app' | translate }}</button>
        </div>
    </div>
</div>