import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { DataService } from '../services/data.service';
import { LanguageService } from '../services/language.service';
import { map, tap } from 'rxjs';

@Component({
  selector: 'app-market-pricing',
  templateUrl: './market-pricing.component.html',
  styleUrls: ['./market-pricing.component.css']
})
export class MarketPricingComponent implements OnInit {

  constructor(private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private dataService: DataService,
    private languageService: LanguageService) {

  }

  showWhatsAppCard = true;
  detectedDevice = 'desktop';
  selectedLanguage = 'en';
  selectedCrop = '';
  selectedCropIndex = 0;
  commodityPriceMarketBoList: any[] = [];
  topCrops: any = ["Corn", "Cotton", "Tomato"];

  closeWhatsAppResponseCard: boolean = false

  @Input()
  userPhoneNumber!: string;
  isPhoneNumberInvalid: boolean = false;

  selectedLanguageISO!: string;

  ngOnInit(): void {
    this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
      .subscribe(screen => {
        if (screen.matches) {
          this.detectedDevice = 'mobile';
        }
        else {
          this.detectedDevice = 'desktop';
        }
      })
    this.selectedCrop = this.activatedRoute.firstChild?.snapshot.paramMap.get('cropName')!;
    this.selectedCrop = this.selectedCrop.toLowerCase();
    let apiResponseData: any = sessionStorage.getItem('apiResponseData')
    if (!apiResponseData) {
      let userLocation = {
        latitude: this.activatedRoute.snapshot.data['location'].latitude,
        longitude: this.activatedRoute.snapshot.data['location'].longitude,
        state: this.activatedRoute.snapshot.data['location'].stateCode,
        city: this.activatedRoute.snapshot.data['location'].city,
        languageCode: 'en',
        languageId: this.languageService.languages.filter((lang: any)=> lang.iso6391 === 'en').map((lang: any)=> lang.languageId)[0]
      }
      this.dataService.getMandiData(userLocation, userLocation.languageId).subscribe((data: any) => {
        this.commodityPriceMarketBoList = data.commodityPricesMarketsDetailsBOList;
        this.topCrops = this.commodityPriceMarketBoList.map((commodity: { commodityName: any; }) => {
          if(!commodity)
            return;
          return commodity.commodityName.toLowerCase()})
        this.selectedCropIndex = this.topCrops.indexOf(this.selectedCrop.toLowerCase());
      })
    }
    this.commodityPriceMarketBoList = JSON.parse(apiResponseData).commodityPricesMarketsDetailsBOList;
    this.topCrops = this.commodityPriceMarketBoList.map((commodity: { commodityName: any; }) => {
      if(!commodity)
        return;
      return commodity.commodityName.toLowerCase()})
    this.selectedCropIndex = this.topCrops.indexOf(this.selectedCrop.toLowerCase());

    this.dataService.isWhatsAppSubscribedObs.subscribe(value => {
      this.showWhatsAppCard = !value;
    })
    this.dataService.isWhatsAppResponseClosedObs.subscribe(value=>{
      this.closeWhatsAppResponseCard = value;
    })
    this.selectedLanguageISO = this.activatedRoute.snapshot.paramMap.get('languageISO') || 'en'
  }

  getDate(date: string | number | Date) {
    if (!date)
      return new Date().getDate();
    return new Date(date).getDate();
  }

  subscribeWhatsapp() {
    if(!/^\d{10}$/.test(this.userPhoneNumber)){
      this.isPhoneNumberInvalid = true;
      return;
    }
    this.isPhoneNumberInvalid = false;
    let language_ISO = sessionStorage.getItem('language_ISO');
    let languageId = this.languageService.languages.filter((lang: any)=> lang.iso6391 === language_ISO).map((lang: any)=> lang.languageId)[0]
    this.dataService.saveWhatsAppSubscription({'userPhoneNumber': this.userPhoneNumber}, languageId).subscribe((response)=> {
      this.showWhatsAppCard = false;
      this.dataService.isWhatsAppSubscribed.next(true);
    })
  }

  closeWhatsAppResponse() {
    this.dataService.isWhatsAppResponseClosed.next(true);
    this.closeWhatsAppResponseCard = true
  }

  showSelectedCropMarkets(cropName: string) {
    this.selectedCrop = cropName.toLowerCase();
    this.selectedCropIndex = this.topCrops.indexOf(this.selectedCrop);
    this.router.navigate([`/market-pricing`, cropName])
  }

}
