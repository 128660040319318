import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { SpinnerUtils } from '../utils/spinner.util';
import { MobileHomePageComponent } from '../mobile-home-page/mobile-home-page.component';
import { DesktopHomePageComponent } from '../desktop-home-page/desktop-home-page.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.css']
})
export class LanguageSelectionComponent implements OnInit {

  languagesSubText = ["English", "Hindi", "Kannada", "Marathi", "Telugu", "Gujarati", "Odiya", "Punjabi", "Bangla", "Tamil"]
  languages: any[] = []
  userLocation = sessionStorage.getItem('userLocation') != null ? sessionStorage.getItem('userLocation')! : '';
  language_ISO = sessionStorage.getItem('language_ISO') != null ? sessionStorage.getItem('language_ISO')! : '';
  detectedDevice = 'desktop';

  defaultSelectedLanguage: any = 'en';

  constructor(private languageService: LanguageService, private translateService: TranslateService,
    private spinnerUtil: SpinnerUtils,
    private mobileHomeComponent: MobileHomePageComponent,
    private desktopHomeComponenet: DesktopHomePageComponent,
    private breakpointObserver: BreakpointObserver,
    private route: Router,) 
    {
      this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
      .subscribe(screen => {
        if (screen.matches) {
          this.detectedDevice = 'mobile'
        }
        else {
          this.detectedDevice = 'desktop'
        }
      })
     }

  ngOnInit(): void {
    this.translateService.use(this.defaultSelectedLanguage)
    this.languages = this.languageService.languages;
    this.defaultSelectedLanguage = sessionStorage.getItem('language_ISO')
  }

  closeLanguageSelectionPopup() {
    sessionStorage.setItem('language_ISO', this.languageService.getLanguageISO())
    this.languageService.displayLangSelPopup.next(false);
  }

  onLanguageSelection(selectedLanguageISO: any) {
    this.languageService.selectedLanguageISO.next(selectedLanguageISO)
    if (this.languageService.getLanguageISO != selectedLanguageISO) {
      this.translateService.use(selectedLanguageISO);
      this.languageService.setLanguageISO(selectedLanguageISO);
      this.languageService.selectedLanguageISO.next(selectedLanguageISO);
      for (let lang of this.languages) {
        if (lang.iso6391 == selectedLanguageISO) {
          this.languageService.setLanguageISO(lang.iso6391);
          this.defaultSelectedLanguage = lang.iso6391;
          sessionStorage.setItem('language_ISO', selectedLanguageISO)
          this.languageService.defaultSelectedLanguage.next(lang.name);
          break;
        }
      }
      // sessionStorage.setItem('language_ISO', selectedLanguageISO)
      this.languageService.languageChange.next(true);
    }
    this.languageService.displayLangSelPopup.next(false);
    let languageId = this.languageService.languages.filter((lang: any)=> lang.iso6391 === selectedLanguageISO).map((lang: any)=> lang.languageId)[0]
    if(this.detectedDevice === 'mobile'){
      this.mobileHomeComponent.getMobileHomeScreenData(JSON.parse(sessionStorage.getItem('userLocation')!), selectedLanguageISO, languageId)
    }
    else {
      this.desktopHomeComponenet.getDesktopHomeScreenData(JSON.parse(sessionStorage.getItem('userLocation')!), selectedLanguageISO, languageId)
    }
    if(this.route.url !== '/') {
      this.languageService.selectedLanguageISOObs.subscribe(value=>{
        this.route.navigate(['/', value])
      })
    }
  }
}
