import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  desktopView: boolean = false
  mobileView: boolean = false

  selectedLanguageISO!: any

  constructor(private router: Router,
    private activeRoute: ActivatedRoute) {
    window.screen.width > 916 ? this.displayView(true) : this.displayView(false)
  }

  ngOnInit(): void {
    this.selectedLanguageISO = this.activeRoute.snapshot.paramMap.get('languageISO') || 'en';
  }

  displayView(isVisible: boolean) {
    this.desktopView = isVisible;
    this.mobileView = !isVisible;
  }

  showPrivacyPolicy() {
    this.router.navigate([this.selectedLanguageISO, 'privacy-policy'])
  }

  showTermsAndConditions(){
    this.router.navigate([this.selectedLanguageISO, 'terms-and-conditions'])
  }
  openCareersPage(){
    window.open(environment.careers);
  }
}