import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { environment } from "../../environments/environment";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  desktopView: boolean = false
  mobileView: boolean = false
  mobileViewMenu = false;

  selectedLanguageISO: any = '';
  selectedLanguageName: any = '';

  

  constructor(private languageService: LanguageService,
    private router: Router) {
    window.screen.width > 916 ? this.displayView(true) : this.displayView(false);
    this.languageService.defaultSelectedLanguageObs.subscribe(value => {
      if(!value){
        this.selectedLanguageISO = sessionStorage.getItem('language_ISO');
        this.selectedLanguageName = this.languageService.languages.filter((lang: any)=> lang.iso6391 === this.selectedLanguageISO).map((lang: any)=> lang.name)[0]
      }
      else{
        this.selectedLanguageName = value;
        this.selectedLanguageISO = this.languageService.languages.filter((lang: any)=> lang.name === value).map((lang: any)=> lang.iso6391.toUpperCase())[0]
      }
    });
  }

  ngOnInit(): void {
  }

  private displayView(isVisible: boolean) {
    this.desktopView = isVisible;
    this.mobileView = !isVisible;
  }

  showMobileMenu() {
    this.mobileViewMenu = !this.mobileViewMenu;
  }

  showSelectedMenuOption(selectedOption: any) {
    this.languageService.selectedLanguageISOObs.subscribe(value => {
      this.router.navigate([value, selectedOption ? selectedOption : '/'])
    })
    this.mobileViewMenu = !this.mobileViewMenu;
  }

  showLanguageSelectionPopup() {
    this.languageService.displayLangSelPopup.next(true);
  }

  openCareersPage(){
    window.open(environment.careers);
  }

  showAboutUs() {
    this.languageService.selectedLanguageISOObs.subscribe(value => {
      this.router.navigate([value, 'about-us'])
    })
  }

  showNews() {
    this.languageService.selectedLanguageISOObs.subscribe(value => {
      this.router.navigate([value, 'news'])
    })
  }
}