
<section class="verify-product-info-container">
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap" rel="stylesheet">
    <div class="verify-product-header">
        <a href="" style="text-decoration: none; color: inherit; display: flex; align-items: center; gap: 1.5%;" [routerLink]="['/', selectedLanguageISO]">
            <img src="../../assets/back-arrow-navigation-svgrepo-com.svg" alt="" height="24px" width="24px">
            <label *ngIf="detectedDevice === 'desktop'" for="" style="font-size: 20px;">Back</label>
        </a>
        <span class="verify-product-header-text">{{'translateStrings.verify_bayer_product' | translate}}</span>
    </div>
    <div class="video-inframe">
        <iframe [src]="videoSrc | safeUrl" width="100%" frameborder="0"></iframe>
    </div>
    <div class="verify-product-subtext">
        <p>{{'translateStrings.verify_bayer_product' | translate}}</p>
    </div>
    <div class="verify-product-description">
        <p>
            {{'translateStrings.verify_bayer_product_description' | translate}}
        </p>
    </div>
</section>
<app-app-download></app-app-download>
<app-footer *ngIf="detectedDevice === 'desktop'"></app-footer>