import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { catchError, throwError } from 'rxjs';
import { SpinnerUtils } from '../utils/spinner.util';
import { environment } from 'src/environments/environment';
import { NavigationService } from '../services/navigation.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-government-schemes',
  templateUrl: './government-schemes.component.html',
  styleUrls: ['./government-schemes.component.css']
})
export class GovernmentSchemesComponent implements OnInit, OnDestroy {

  detectedDevice = 'desktop';
  schemeId: string = '';
  schemeName: string = '';
  showErrorResponse: boolean = false;
  govtSchemeApiResponse: any;
  selectedLanguageISO!: string;

  constructor(private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService, private activatedRoute: ActivatedRoute,
    private dataService: DataService, private spinnerUtil: SpinnerUtils,
    private navigationService: NavigationService,
    private router: Router,
    private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(Breakpoints.Handset)
      .subscribe(screen => {
        if (screen.matches) {
          this.detectedDevice = 'mobile'
        }
        else {
          this.detectedDevice = 'desktop'
        }
      })
      this.translateService.use(sessionStorage.getItem('language_ISO')!);
      if(sessionStorage.getItem('govtSchemeApiResponse')){
        this.govtSchemeApiResponse = JSON.parse(sessionStorage.getItem('govtSchemeApiResponse')!);
        return;
      }
      this.spinnerUtil.showSpinner();
      let userLocation = sessionStorage.getItem('userLocation') ? JSON.parse(sessionStorage.getItem('userLocation')!) :
        { city: 'Bengaluru',
          state: 'KA', 
          languageId: 1,
          languageISO: 'en' 
        }; // to be modified with location changes in the url

      // Retrieve govt scheme name from route parameters
      this.activatedRoute.paramMap.subscribe(params => {
        this.schemeName = params.get('schemeName')!;
        this.selectedLanguageISO = params.get('languageISO') || 'en';
        // Retrieve schemeId from the navigation service
        const state = this.navigationService.getNavigationState();
        if (state && state.schemeId) {
          this.schemeId = state.schemeId;
          this.fetchGovtScheme(userLocation);
        } else {
          this.showErrorResponse = true;
          this.spinnerUtil.hideSpinner();
        }
      });
  }

  private fetchGovtScheme(userLocation: any) {
    this.dataService.getGovtScheme(this.schemeId, userLocation.languageId, userLocation.state).pipe(
      catchError(err => {
        this.showErrorResponse = true;
        return throwError(err)
      })).subscribe(async(data) =>{
        this.govtSchemeApiResponse = data;
        sessionStorage.setItem('govtSchemeApiResponse', JSON.stringify(this.govtSchemeApiResponse))
        this.spinnerUtil.hideSpinner();
      })
  }

  downloadApp() {
    window.open(environment.appDownloadBannerDynamicLink, "appDownloadBannerDynamicLink")
  }


  ngOnDestroy(): void {
    sessionStorage.removeItem('govtSchemeApiResponse')
  }

}
