
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private state: any;

  setNavigationState(state: any) {
    this.state = state;
  }

  getNavigationState() {
    const state = this.state;
    this.state = null; // Clear the state after retrieving it
    return state;
  }
}