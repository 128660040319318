import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot} from '@angular/router';
import { BehaviorSubject, Observable, catchError, from, map, of, switchMap, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class LocationResolver implements Resolve<any> {


  private readonly LOCATION_KEY = 'userLocation';
  private location: BehaviorSubject<any> = new BehaviorSubject<any>(sessionStorage.getItem(this.LOCATION_KEY) ? JSON.parse(sessionStorage.getItem(this.LOCATION_KEY)!) : null);
  locationObs = this.location.asObservable();

  constructor(private http: HttpClient) {
    const storedLocation = sessionStorage.getItem('userLocation');
    this.location = new BehaviorSubject<any>(storedLocation ? JSON.parse(storedLocation) : null);
  }

  /**resolver to get user location based on any city search or current location obtained from browser or using ip     address as last resort
   * @param route
   * @param state
   * */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const urlCity = route.paramMap.get('city') || route.firstChild?.paramMap.get('city');
    const languageISO = route.paramMap.get('languageISO') || 'en';
    if (this.location.value) {
      return of(this.location.value);
    }
    return from(this.getCurrentLatLong())
      .pipe(
        switchMap((position: GeolocationPosition) => {
          return urlCity ? this.resolveLocation(languageISO, urlCity)
             : this.resolveLocation(languageISO, null, position.coords.latitude.toString(), position.coords.longitude.toString())
        }),
        catchError(() => {
          return this.resolveLocation(languageISO, urlCity || 'Bangalore');
        })
      )
  }

  resolveLocation(languageISO: string, city?: any, latitude?: string, longitude?: string) {
    return this.getUserGeoLocation(languageISO, city, latitude, longitude).pipe(
      map((location: any) => ({
        latitude: location.data.latitude,
        longitude: location.data.longitude,
        stateCode: location.data.stateCode,
        stateName: location.data.stateName,
        city: location.data.city
      })),
      tap((location: any) => {
        this.updateLocation(location)
      })
    );
  }

  updateLocation(location: any) {
    this.location.next(location);
    sessionStorage.setItem(this.LOCATION_KEY, JSON.stringify(location));
  }

  private getCurrentLatLong(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject('Geolocation not available');
      }
    });
  }

  getUserGeoLocation(languageISO: string, city?: any, latitude?: string, longitude?: string): Observable<any> {
    let url = environment.websiteAggregatorServiceUrl + "/user/location";
    let headers = new HttpHeaders({
      'requestId': uuid.v4(),
      'x-api-key': environment.xApiKey
    })
    let queryParams = { // API query parameters with optional values of city, latitude and longitude
      ...city ? { city: city } : {},
      ...latitude ? { latitude: latitude } : {},
      ...longitude ? { longitude: longitude } : {},
      languageISO: languageISO
    }
    return this.http.get(url, { headers: headers, params: queryParams })
  }
}
