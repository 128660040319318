<section>
    <div class="header" *ngIf="desktopView">
        <img class="bayerDesktopLogo" src="assets/ic_logo.png" alt="logo" [routerLink]="['/', selectedLanguageISO?.toLowerCase()]">
        <div class="flex-right">
            <div class="home">
                <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap" rel="stylesheet">
                <a href="" style="text-decoration: none; color: inherit;" [routerLink]="['/', selectedLanguageISO?.toLowerCase()]">{{'translateStrings.home' | translate}}</a>
            </div>
            <div class="careers" (click)="openCareersPage()">
                {{'translateStrings.careers' | translate}}
            </div>
            <div *ngIf="selectedLanguageName!=''" class="languageSelection" (click)="showLanguageSelectionPopup()">
                <img src="../../assets/ic_translate.svg" alt="">
                <span class="selectedLanguage">{{ selectedLanguageName }}</span>
            </div>
        </div>
    </div>
    <div class="bayerMobileLogo" *ngIf="mobileView">
        <img style="width: 176px; height: 37px;" src="assets/ic_logo.png" alt="logo" [routerLink]="['/', selectedLanguageISO?.toLowerCase()]">
        <div class="languageSelectionForMobile" (click)="showLanguageSelectionPopup()">
            <img src="../../assets/ic_translate.svg" alt="">
            <span class="selectedLanguage">{{ selectedLanguageISO | uppercase}}</span>
        </div>
        <div *ngIf="!mobileViewMenu" class="ham-menu-mobile" (click)="showMobileMenu()">
            <img src="../../assets/ic_ham_menu.png" alt="" height="18px" width="24px">
        </div>
        <div *ngIf="mobileViewMenu" class="mobile-menu-card">
            <div class="menu-close" (click)="showMobileMenu()">
                <img src="../../assets/ic_close.png" alt="" height="24px">
            </div>
            <div class="menu-items">
                <span class="menu-item-active"><a href="" style="text-decoration: none; color: inherit;"
                        (click)="showMobileMenu()">{{'translateStrings.home' | translate}}</a></span>
                <span class="menu-items-others"><a style="text-decoration: none; color: inherit;"
                        (click)="showSelectedMenuOption('about-us')">{{'translateStrings.about_us' | translate}}</a></span>
                <span class="menu-items-others"><a style="text-decoration: none; color: inherit;"
                        (click)="showSelectedMenuOption('news')">{{'translateStrings.news_media' | translate}}</a></span>
                <span class="menu-items-others"><a (click)="openCareersPage()"
                        style="text-decoration: none; color: inherit;">{{'translateStrings.careers' | translate}}</a></span>
            </div>
            <app-app-download-banner *ngIf="mobileView && mobileViewMenu"></app-app-download-banner>
        </div>
    </div>
    <div class="dividerLine">
        <hr>
    </div>
</section>
