export const Constants: any = {
    weather_icons : {
        CLOUDY: '../../assets/weather-icons/cloudy.svg',
        BLIZZARD: '../../assets/weather-icons/blizzard.svg',
        BREEZY: '../../assets/weather-icons/breezy.svg',
        DRIFTING_SNOW: '../../assets/weather-icons/drifting_snow.svg',
        DRIZZLE: '../../assets/weather-icons/drizzle.svg',
        FLURRIES: '../../assets/weather-icons/flurries.svg',
        FOGGY: '../../assets/weather-icons/foggy.svg',
        FREEZING_DRIZZLE: '../../assets/weather-icons/freezing_drizzle.svg',
        FREEZING_RAIN: '../../assets/weather-icons/freezing_rain.svg',
        HAIL: '../../assets/weather-icons/hail.svg',
        HAZE: '../../assets/weather-icons/haze.svg',
        HEAVY_RAIN: '../../assets/weather-icons/heavy_rain.svg',
        HEAVY_SNOW: '../../assets/weather-icons/heavy_snow.svg',
        HOT: '../../assets/weather-icons/hot.svg',
        HURRICANE: '../../assets/weather-icons/hurricane.svg',
        ICE_CRYSTALS: '../../assets/weather-icons/ice_crystals.svg',
        ISOLATED_THUNDERSTORMS: '../../assets/weather-icons/isolated_thunderstorms.svg',
        MIXED_RAIN_AND_HAIL: '../../assets/weather-icons/mixed_rain_and_hail.svg',
        MOSTLY_CLOUDY: '../../assets/weather-icons/mostly_cloudy.svg',
        MOSTLY_SUNNY: '../../assets/weather-icons/mostly_sunny.svg',
        PARTLY_CLOUDY: '../../assets/weather-icons/partly_cloudy.svg',
        RAIN: '../../assets/weather-icons/rain.svg',
        SANDSTORM: '../../assets/weather-icons/sandstorm.svg',
        SCATTERED_SHOWERS: '../../assets/weather-icons/scattered_showers.svg',
        SCATTERED_SNOW_SHOWERS: '../../assets/weather-icons/scattered_snow_showers.svg',
        SCATTERED_THUNDERSTORMS: '../../assets/weather-icons/scattered_thunderstorms.svg',
        SHOWERS: '../../assets/weather-icons/showers.svg',
        SLEET: '../../assets/weather-icons/sleet.svg',
        SMOKE: '../../assets/weather-icons/smoke.svg',
        SNOW_SHOWERS: '../../assets/weather-icons/snow_showers.svg',
        SNOW: '../../assets/weather-icons/snow.svg',
        STRONG_STORMS: '../../assets/weather-icons/strong_storms.svg',
        SUNNY: '../../assets/weather-icons/sunny.svg',
        THUNDERSTORMS: '../../assets/weather-icons/thunderstorms.svg',
        TORNADO: '../../assets/weather-icons/tornado.svg',
        TROPICAL_STORM: '../../assets/weather-icons/tropical_storm.svg',
        WEATHER_ERROR: '../../assets/weather-icons/weather_error.svg',
        WINDY: '../../assets/weather-icons/windy.svg',
        WINTRY_MIX: '../../assets/weather-icons/wintry_mix.svg'
    },
    weather_names : {
        CLOUDY: 'Cloudy',
        BLIZZARD: 'Blizzard',
        BREEZY: 'Breezy',
        DRIFTING_SNOW: 'Drifting Snow',
        DRIZZLE: 'Drizzle',
        FLURRIES: 'Flurries',
        FOGGY: 'Foggy',
        FREEZING_DRIZZLE: 'Freezing Drizzle',
        FREEZING_RAIN: 'Freezing Rain',
        HAIL: 'Hail',
        HAZE: 'Haze',
        HEAVY_RAIN: 'Heavy Rain',
        HEAVY_SNOW: 'Heavy Snow',
        HOT: 'Hot',
        HURRICANE: 'Hurricane',
        ICE_CRYSTALS: 'Ice Crystals',
        ISOLATED_THUNDERSTORMS: 'Isolated Thunderstorms',
        MIXED_RAIN_AND_HAIL: 'Mixed Rain and Hail',
        MOSTLY_CLOUDY: 'Mostly Cloudy',
        MOSTLY_SUNNY: 'Mostly Sunny',
        PARTLY_CLOUDY: 'Partly Cloudy',
        RAIN: 'Rain',
        SANDSTORM: 'Sandstorm',
        SCATTERED_SHOWERS: 'Scattered Showers',
        SCATTERED_SNOW_SHOWERS: 'Scattered Snow Showers',
        SCATTERED_THUNDERSTORMS: 'Scattered Thunderstorms',
        SHOWERS: 'Showers',
        SLEET: 'Sleet',
        SMOKE: 'Smoke',
        SNOW_SHOWERS: 'Snow Showers',
        SNOW: 'Snow',
        STRONG_STORMS: 'Strong Storms',
        SUNNY: 'Sunny',
        THUNDERSTORMS: 'Thunderstorms',
        TORNADO: 'Tornado',
        TROPICAL_STORM: 'Tropical Storm',
        WEATHER_ERROR: '- -',
        WINDY: 'Windy',
        WINTRY_MIX: 'Wintry Mix'
    }
}